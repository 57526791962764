import api from '@apiInstance';

export default {
  add (types) {
    return api.post('company_types', types);
  },
  get (id) {
    return api.fetch(`company_types/${id}`);
  },
  list (params) {
    return api.fetch('/company_types', { params });
  },
  update (types) {
    return api.patch('company_types', types);
  },
  delete (id) {
    return api.remove('company_types', id);
  }
};
